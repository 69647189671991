import React, { useState, useRef, useEffect } from 'react';

function Content2Column({ data }) {
    const [showFullDescriptionLeft, setShowFullDescriptionLeft] = useState(false);
    const [showFullDescriptionRight, setShowFullDescriptionRight] = useState(false);
    const [isContentTruncatedLeft, setIsContentTruncatedLeft] = useState(false);
    const [isContentTruncatedRight, setIsContentTruncatedRight] = useState(false);
    const [totalLinesLeft, setTotalLinesLeft] = useState(0);
    const [totalLinesRight, setTotalLinesRight] = useState(0);

    const leftColumnRef = useRef(null);
    const rightColumnRef = useRef(null);
    const contentRefLeft = useRef(null);
    const elementRefLeft = useRef(null);
    const contentRefRight = useRef(null);
    const elementRefRight = useRef(null);

    const cleanContent = (content) => {
        if (!content) return '';
        return content.replace(/<p>\s*<\/p>/g, ''); 
    };

    const checkContentTruncation = (
        contentRef, 
        elementRef, 
        setTotalLines, 
        setIsContentTruncated, 
        setShowFullContent
    ) => {
        const contentElement = contentRef.current;
        const element = elementRef.current;

        if (element && contentElement) {
            const totalHeight = element.scrollHeight;
            const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight, 10);
            const calculatedTotalLines = Math.floor(totalHeight / lineHeight);
            setTotalLines(calculatedTotalLines);

            if (calculatedTotalLines <= 15) {
                setIsContentTruncated(false);
                setShowFullContent(false);
            } else if (calculatedTotalLines === 16) {
                setIsContentTruncated(false);
                setShowFullContent(true);
            } else {
                setIsContentTruncated(true);
                setShowFullContent(false);
            }
        }
    };

    useEffect(() => {
        checkContentTruncation(
            contentRefLeft, 
            elementRefLeft, 
            setTotalLinesLeft, 
            setIsContentTruncatedLeft, 
            setShowFullDescriptionLeft
        );
        checkContentTruncation(
            contentRefRight, 
            elementRefRight, 
            setTotalLinesRight, 
            setIsContentTruncatedRight, 
            setShowFullDescriptionRight
        );

        const handleResize = () => {
            checkContentTruncation(
                contentRefLeft, 
                elementRefLeft, 
                setTotalLinesLeft, 
                setIsContentTruncatedLeft, 
                setShowFullDescriptionLeft
            );
            checkContentTruncation(
                contentRefRight, 
                elementRefRight, 
                setTotalLinesRight, 
                setIsContentTruncatedRight, 
                setShowFullDescriptionRight
            );
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [data.column_1_content, data.column_2_content]);

    const toggleDescriptionLeft = () => {
        setShowFullDescriptionLeft(!showFullDescriptionLeft);
    };

    const toggleDescriptionRight = () => {
        setShowFullDescriptionRight(!showFullDescriptionRight);
    };

    return (
        <section className="who-heading">
            <div className="container">
                <div className="who-sub row">
                    {data.column_1_title || data.column_1_content ? (
                        <div className={data.column_2_title || data.column_2_content ? 'col-md-6' : 'col-md-12'}>
                            <div className="who-left" ref={leftColumnRef}>
                                <h2 dangerouslySetInnerHTML={{ __html: data?.column_1_title }}></h2>

                                <div
                                    ref={contentRefLeft}
                                    style={{
                                        maxHeight: showFullDescriptionLeft ? 'none' : 'unset',
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease-out',
                                    }}
                                >
                                    <div
                                        ref={elementRefLeft}
                                        className="content"
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullDescriptionLeft
                                                ? totalLinesLeft
                                                : (totalLinesLeft > 16 ? 14 : totalLinesLeft),
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: cleanContent(data?.column_1_content) }}
                                    ></div>
                                </div>

                                {isContentTruncatedLeft && (
                                    <button 
                                        onClick={toggleDescriptionLeft} 
                                        className="bttn"
                                        // style={{ display: 'block', marginTop: '10px' }}
                                    >
                                        {showFullDescriptionLeft ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {data.column_2_title || data.column_2_content ? (
                        <div className={data.column_1_title || data.column_1_content ? 'col-md-6' : 'col-md-12'}>
                            <div className="who-right" ref={rightColumnRef}>
                                <h2 dangerouslySetInnerHTML={{ __html: data?.column_2_title }}></h2>

                                <div
                                    ref={contentRefRight}
                                    style={{
                                        maxHeight: showFullDescriptionRight ? 'none' : 'unset',
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease-out',
                                    }}
                                >
                                    <div
                                        ref={elementRefRight}
                                        className="content"
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullDescriptionRight
                                                ? totalLinesRight
                                                : (totalLinesRight > 16 ? 14 : totalLinesRight),
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: cleanContent(data?.column_2_content) }}
                                    ></div>
                                </div>

                                {isContentTruncatedRight && (
                                    <button 
                                        onClick={toggleDescriptionRight} 
                                        className="bttn"
                                        // style={{ display: 'block', marginTop: '10px' }}
                                    >
                                        {showFullDescriptionRight ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </section>
    );
}

export default Content2Column;