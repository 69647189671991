import React, { useState, useEffect, useRef } from 'react';
import styles from './ImageContentBlock.module.scss';
import useSelectTextColor from '../../../../common/hooks/useSelectTextColor';

function ImageContentBlock({ data }) {
    const [showFullContent, setShowFullContent] = useState(false);
    const contentRef = useRef(null);
    const elementRef = useRef(null);
    const [isContentTruncated, setIsContentTruncated] = useState(false);
    const [totalLines, setTotalLines] = useState(0);

    const cleanContent = (content) => {
        if (!content) return '';
        return content.replace(/<p>\s*<\/p>/g, ''); 
    };
    
    const checkContentTruncation = () => {
        const contentElement = contentRef.current;
        const element = elementRef.current;

        if (element && contentElement) {
            const totalHeight = element.scrollHeight;
            const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight, 10);
            const calculatedTotalLines = Math.floor(totalHeight / lineHeight);
            setTotalLines(calculatedTotalLines);

            if (calculatedTotalLines <= 15) {
                setIsContentTruncated(false);
                setShowFullContent(false);
            } else if (calculatedTotalLines === 16) {
                setIsContentTruncated(false);
                setShowFullContent(true);
            } else {
                setIsContentTruncated(true);
                setShowFullContent(false);
            }
        }
    };

    useEffect(() => {
        checkContentTruncation();
        const handleResize = () => {
            checkContentTruncation();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [data.content]);

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };

    const selectTextColor = useSelectTextColor();
    const sectionBgColor = data.hasOwnProperty('background_color') ? data.background_color : '#ffffff';
    const textColor = selectTextColor(sectionBgColor);
    const LinkTextColor = sectionBgColor === '#ffffff' ? '#365bb8' : textColor;
    const TitleTextColor = data.hasOwnProperty('title_text_color') ? data.title_text_color : '#000000';

    return (
        <div
            className={styles.imageContentWrap}
            style={{
                '--image_section_bg_color': sectionBgColor,
                '--image_section_text_color': textColor,
                '--link_text_color': LinkTextColor,
            }}
        >
            <div className="container">
                <div className={styles.imageContentBlock}>
                    {data.reverse ? (
                        <div className="row">
                            <div className={`${styles.imgBlock} col-md-6`}>
                                <img src={data.image_url} alt="" />
                            </div>
                            <div className={`${styles.contents} col-md-6`}>
                                <h2 style={{ color: TitleTextColor }}>{data.title}</h2>
                                <div
                                    ref={contentRef}
                                    style={{
                                        maxHeight: showFullContent ? 'none' : 'unset',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <div
                                        ref={elementRef}
                                        className={styles.contentText}
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullContent 
                                                ? totalLines  
                                                : (totalLines > 16 ? 14 : totalLines), 
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: cleanContent(data.content) }}
                                    ></div>
                                </div>
                                {isContentTruncated && (
                                    <button
                                        onClick={toggleContent}
                                        className={`${styles.bttn} bttn`}
                                    >
                                        {showFullContent ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={`${styles.imageRows} row`}>
                            <div className={`${styles.contents} col-md-6`}>
                                <h2 style={{ color: TitleTextColor }}>{data.title}</h2>
                                <div
                                    ref={contentRef}
                                    style={{
                                        maxHeight: showFullContent ? 'none' : 'unset',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <div
                                        ref={elementRef}
                                        className={styles.contentText}
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullContent 
                                                ? totalLines  // Show all lines when expanded
                                                : (totalLines > 16 ? 14 : totalLines), 
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: cleanContent(data.content) }}
                                    ></div>
                                </div>
                                {isContentTruncated && (
                                    <button
                                        onClick={toggleContent}
                                        className={`${styles.bttn} bttn`}
                                    >
                                        {showFullContent ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                            <div className={`${styles.imgBlock} col-md-6`}>
                                <img src={data.image_url} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ImageContentBlock;

