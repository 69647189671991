import { useFormik } from 'formik'
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup'
import { useGetContractTypesQuery, useGetSectorsQuery } from '../../JobPost/api/jobCreateApiSlice';

function useSearchForm() {

    const navigate = useNavigate();
    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const keyword = queryParams.get('keyword');
    const location = queryParams.get('location');
    const postedDate = queryParams.get('posted-date');
    const page = queryParams.get('page')
    const location_radius = queryParams.get('location-radius');
    const { data: contractData } = useGetContractTypesQuery()
    const { data: sectorData } = useGetSectorsQuery()

    const initialValues = {
        keyword: keyword ?? '',
        location: location ?? '',
        contractTypes: [],
        sector: [],
        postedDate: postedDate ?? '',
        location_radius: location_radius ? Number(location_radius) : 0,
        page: page ?? 1
    }

    const isPresent = (array, searchString) => {
        const lowerCaseSearchString = searchString.toLowerCase();
        return array?.find(item => item.label.toLowerCase() === lowerCaseSearchString) || false;
    };

    const validationSchema = Yup.object({
        'keyword': Yup.string(),
        'location': Yup.string(),
        'contractTypes': Yup.array(),
        'minSalary': Yup.number(),
        'maxSalary': Yup.number(),
        'postedDate': Yup.string(),
        'location_radius': Yup.number(),
    })

    const searchForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {

            var matchedSector = isPresent(sectorData, values.keyword)
            var matchedcontractType = isPresent(contractData, values.keyword)

            if (matchedSector && !values.sector.includes(matchedSector.label)) {
                values.sector.push(matchedSector.label)
                values.keyword = ''
            }
            if (matchedcontractType && !values.contractTypes.includes(matchedcontractType.label)) {
                values.contractTypes.push(matchedcontractType.label)
                values.keyword = ''
            }

            if (values.minSalary !== undefined && values.minSalary !== 0 && values.maxSalary !== undefined && values.maxSalary !== 0 && values.minSalary > values.maxSalary) {
                [values.minSalary, values.maxSalary] = [values.maxSalary, values.minSalary];
            }
            let query = `/find-a-job?keyword=${values.keyword}&location=${values.location}`
            if (values.contractTypes?.length > 0) {
                query += `&contract-types=${values.contractTypes}`
            }
            if ((values.minSalary !== undefined)) {
                query += `&salary-min=${values.minSalary}`
            }
            if ((values.maxSalary !== undefined)) {
                query += `&salary-max=${values.maxSalary}`
            }
            if ((values.minSalary === 0 || values.minSalary === undefined) && (values.maxSalary === 0 || values.maxSalary === undefined)) {
                query += '';
            }
            if ((values.postedDate !== '') || (values.postedDate !== undefined)) {
                query += `&posted-date=${values.postedDate}`
            }
            if (values.sector.length > 0) {
                const encodedSectors = values.sector.map(item => encodeURIComponent(item));
                query += `&sector=${encodedSectors}`
            }
            if ((values.location_radius)) {
                query += `&location-radius=${values.location_radius}`
            }
            if ((values.page)) {
                query += `&page=${values.page}`
            }
            navigate(query);
        }
    })

    return { searchForm }
}

export default useSearchForm